@import 'common.scss';

.delegation-chart {
  height: 400px;

  .chart-tooltip {
    background-color: #f8f8f8;
    padding: 4px;
    border: 1px grey solid;
    border-radius: 6px;
    font-weight: bold;
    opacity: 0.8;

    .chart-y-value {
      color: $brand-dark;
    }
  }
}
