@import 'common.scss';

.delegation-account-card {
  border: 1px solid transparent;
  transition : border 500ms ease-out;

  &:hover {
    border: 1px solid $primary;
    cursor: pointer;
  }

  .media-left .image {
    width: 44px;
  }

  .columns {
    margin-bottom: 0;

    &.header {
      .column:nth-child(1) {
        padding-right: 0;
      }
    }
  }

  .column {
    padding-bottom: 0;
  }

  table {
    width: 100%;
    table-layout: fixed;
  }

  tr {
    max-width: 100%;

    td:nth-child(1) {
      width: 84px;
    }

    td:nth-child(2) {
      width: 100%;
    }

    td:nth-child(3) {
      width: 70px;
    }
  }

  td {
    border: 0;

    &.align-with-table-btn {
      padding-top: 12px;
    }

    .formatted-amount {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
