@charset "utf-8";

@import 'setup';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
}

html,
body {
  height: 100%;
}

.delegation-page-section {
  padding-top: 5px;

  & > .container {
    margin: 2px;
    max-width: 1200px;
  }
}

.delegation-app-modal {
  header,
  footer {
    border: 0;
    background-color: white;
  }

  footer {
    justify-content: flex-end;
  }
}

button.brand-btn {
  background-color: $brand-dark;
  color: white;

  &:hover {
    color: $grey-dark;
    background-color: $brand-light;
    border-color: $brand-light;
  }

  &[disabled] {
    background-color: $brand-light;
  }
}

td.primary-row-data {
  background-color: $primary;
  color: white;
}

td.rounded-row-data {
  border-radius: 4px;
}

td.secondary-row-data {
  background-color: #f3f3f3;
}

td.row-label {
  padding-right: 1px;
  padding-left: 0;
  font-weight: bold;
  font-style: italic;
}

.notification-message .message {
  background-color: transparent;
}

.global-spinner {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 0.7;
  transition: all 0.4s;

  &.is-active {
    display: flex;
  }

  img {
    filter: grayscale(100%);
    animation: spin 3s linear infinite;
    width: 90px;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

.pagination-btn {
  margin: 4px;
  padding: 0 14px;
  border: 2px solid $brand-dark;
  border-radius: 100px;

  &:hover {
    color: $dark;
    background-color: $brand-light;
    border-color: transparent;
  }

  &.is-active {
    color: white;
    background-color: $brand-dark;
    border: 2px solid $brand-dark;
  }
}

.control.has-pointer-events-icon .icon {
  pointer-events: all;
}
