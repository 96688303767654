@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@0;1&display=swap');
// Set your brand colors colors from Sasha
$brand-dark: #008da0;
$brand-light: #00f2d0;
$brand-lighter: #defdf9;

$white-light: #fbfbfe;
$white-dark: #f3f3f3;

$grey-lighter: #ccc;
$grey-light: #9b9b9b;
$grey-dark: #384243;
$black: #000000;
$linked-in: #0077b5;

// Update Bulma's global variables
// $family-primary: 'proxima-nova', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-primary: 'Nunito Sans', BlinkMacSystemFont, -apple-system, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$family-sans-serif: $family-primary;
$link: $brand-dark;

$widescreen-enabled: true;
$fullhd-enabled: false;
$panel-heading-size: 1rem;

@import '../../node_modules/bulma/bulma.sass';
@import '~@fortawesome/fontawesome-free/css/all.css';
