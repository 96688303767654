table.account-details {
  table-layout: fixed;
  min-width: 666px;

  td.delegation-label, td.rewards-label {
    width: 84px;
  }

  td.amount {
    width: 80px;
  }

  td.actions {
    width: 112px;
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
  }
}

.formatted-amount {
  display: inline-block;
  min-width: 60px;
  max-width: 108px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
