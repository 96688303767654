@import 'common.scss';

.account-transaction-history-table {
  [role="row"] {
    border-bottom: 2px solid #cacaca;
  }

  [role="columnheader"] > div {
    color: #9b9b9b;
    font-style: italic;
    font-weight: bold;
  }
}
